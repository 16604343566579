import { RootStore, BindParams } from '../../../RootStore/RootStore';
import {
  SelectedOption,
  ComponentIds as Ids,
  EnabledOptions,
} from '../../../config/constants';
import { runInAction } from 'mobx';
import { TipTypes } from '@wix/wix-tips-common';

const ButtonState = {
  Selected: 'selected',
  Default: 'default',
};

const bind = (rootStore: RootStore): BindParams => {
  const { t, $state } = rootStore;
  const onButtonClick = () => {
    if ($state.selectedOption !== SelectedOption.Custom) {
      runInAction(() => {
        $state.selectedTip = { type: TipTypes.Amount, value: '0' };
        $state.isUserSelectedCustomTip = true;
      });
      rootStore.updateTip();
      rootStore.$w(Ids.CustomTipInput).focus();
    }
  };
  const buttonLabel = t('checkout.tips.customAmount.selection');

  return {
    [Ids.CustomTipButtonWrapper]: {
      deleted: () => $state.enabledOptions !== EnabledOptions.All,
    },
    [Ids.CustomTipStateBox]: {
      currentState: () => {
        return $state.selectedOption === SelectedOption.Custom
          ? ButtonState.Selected
          : ButtonState.Default;
      },
    },
    [Ids.CustomTipDefaultButton]: {
      label: () => buttonLabel,
      onClick: onButtonClick,
    },
    [Ids.CustomTipSelectedButton]: {
      label: () => buttonLabel,
      onClick: onButtonClick,
    },
  };
};

export default bind;
