import { BindParams } from '../../../RootStore/RootStore';
import { ComponentIds as Ids } from '../../../config/constants';

const bind = (): BindParams => {
  return {
    [Ids.CustomValueRepeater]: {
      deleted: () => true,
    },
  };
};

export default bind;
