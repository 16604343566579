
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript/wrapper.js';
  
  
            
import wrapController0 from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/blocks';

            import controller0 from '/home/builduser/work/76a9163c0c6d3df1/packages/wix-tips-storefront-blocks/src/components/TipsNew/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/76a9163c0c6d3df1/packages/wix-tips-storefront-blocks/src/components/TipsNew/model.ts';
            var controllerExport0 = _controllerExport0;
            

  
  var importedApp = {};


  

    import { initState, initBind } from '@wix/yoshi-flow-editor/runtime/esm/velocycleMobx';
    import { observable } from 'mobx';
    var velocycleMobx = { initState, initBind, observable };
    


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocks/controllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://6117f2172a744ef3913942830d472a2c@sentry.wixpress.com/4876',
      id: '6117f2172a744ef3913942830d472a2c',
      projectName: 'wix-tips-storefront-blocks',
      teamName: 'wix-tips',
      errorMonitor: true,
    };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/76a9163c0c6d3df1/packages/wix-tips-storefront-blocks/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","checkout.tips.customAmount.label":"Enter a custom amount","checkout.tips.customAmount.cta":"Apply","checkout.tips.presetOptions.selection":"{amount}%","checkout.tips.customAmount.currencyLabel":"{currency}","checkout.tips.title":"Want to leave a tip?","checkout.tips.presetOptions.label":"Select an amount","checkout.tips.customAmount.selection":"Custom amount","checkout.tips.validationError.tooManyDigits":"Enter 2 digits max after the decimal point.","checkout.tips.validationError.noCharactersLetters":"Enter an amount without letters or special characters.","checkout.tips.validationError.technicalError":"Tip wasn’t applied due to a technical error. Please try again.","checkout.tips.validationError.connectionError":"Couldn't apply tip. Connect to the internet and try again.","checkout.tips.validationError.emptyField":"Enter a tip amount.","checkout.tips.validationError.negativeAmount":"Tip amount can’t be negative.","checkout.tips.customAmountCurrency.ariaLabel":"Tip amount in {currency}","checkout.tips.customAmountApplied.ariaLabel":"Applied successfully"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/76a9163c0c6d3df1/packages/wix-tips-storefront-blocks/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    multilingualDisabled,
    projectName: "wix-tips-storefront-blocks",
    biConfig: null,
    appName: "Wix Tips",
    appDefinitionId: "9dd67198-68df-4884-92d5-d38ba59bcae0",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig,
          multilingualDisabled,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/76a9163c0c6d3df1/packages/wix-tips-storefront-blocks/src/components/TipsNew/viewer.controller.ts",
          appName: "Wix Tips",
          appDefinitionId: "9dd67198-68df-4884-92d5-d38ba59bcae0",
          projectName: "wix-tips-storefront-blocks",
          componentName: "TipsNew",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "9dd67198-68df-4884-92d5-d38ba59bcae0-x9mwh" }],
    false);

    export const createControllers = _createControllers
