export const ComponentIds = {
  Title: '#title',
  RadioGroupBox: '#radioGroupBox',
  PresetsRepeater: '#presetsRepeater',
  PresetsRepeaterItem: '#selectableContainer',
  PresetsRepeaterItemInput: '#selectableContainerInput',
  CustomTipInput: '#customTipInput',
  CustomTipFieldBox: '#customTipFieldBox',
  CustomTipButtonWrapper: '#customTipBtnWrapper',
  CustomTipStateBox: '#customTipStateBox',
  CustomTipSelectedButton: '#customTipSelectedBtn',
  CustomTipDefaultButton: '#customTipDefaultBtn',
  CustomTipApplyButton: '#applyButton',
  CustomTipSuccessButton: '#successButton',
  CustomValueRepeater: '#customTipRepeater',
  CustomValueRepeaterItem: '#selectableContainer1',
  ButtonStateBox: '#statesBtn',
  ErrorText: '#errorText',
  ErrorInnerBox: '#errorInnerBox',
  ErrorOuterBox: '#errorOuterBox',
  RootBox: '#box1',
} as const;

export const enum SelectedOption {
  Presets,
  Custom,
  None,
}

export const enum EnabledOptions {
  None,
  PresetsOnly,
  CustomOnly,
  All,
}
