import { runInAction, toJS } from 'mobx';
import { RootStore } from './RootStore';
import { setTip } from '../../../api/tip/TipApi';
import { debounce, uniq } from 'lodash';
import { checkoutTipsTipAddedToCheckout } from '@wix/bi-logger-ec-site/v2';
import { SelectedOption } from '../config/constants';
import { GUID } from '@wix/bi-logger-ec-site/dist/types/v2/types';
import { TipTypes } from '@wix/wix-tips-common';
import { FedopsInteractions } from './fedops';

const handleErrorResponse = (error, rootStore) => {
  console.error('Failed to update tip', error.response);
  let errorMessage;
  const statusCode = error.response?.status;
  if (statusCode && statusCode >= 400 && statusCode < 600) {
    errorMessage = rootStore.t('checkout.tips.validationError.technicalError');
  } else {
    errorMessage = rootStore.t('checkout.tips.validationError.connectionError');
  }
  runInAction(() => {
    rootStore.$state.error = errorMessage;
  });
  return { error, success: false };
};

const sendTipAddedBiEvent = (
  rootStore: RootStore,
  tip: { value: number; type: TipTypes },
) => {
  const { checkout, selectedOption, purchaseFlowId } = rootStore.$state;
  const lineItems = checkout?.lineItems ?? [];
  const uniqueAppIds = uniq(lineItems.map((v) => v.catalogReference?.appId));
  rootStore.controllerParams.flowAPI.bi?.report(
    checkoutTipsTipAddedToCheckout({
      rate: tip.value * 100000,
      tipType: tip.type === TipTypes.Percentage ? 'Percentage' : 'Fixed amount',
      isCustomTip: selectedOption === SelectedOption.Custom,
      // todo: rename ecomId to purchaseFlowId in BI events
      ecomId: purchaseFlowId as GUID,
      checkoutId: checkout?.id as GUID,
      catalogAppId: uniqueAppIds.join(', '),
    }),
  );
};

export const updateTip = async (
  rootStore: RootStore,
): Promise<{ success?: boolean; error?: string }> => {
  const { httpClient, fedops } = rootStore;
  const { checkout } = rootStore.$state;
  fedops.interactionStarted(FedopsInteractions.SET_TIP);
  if (checkout?.purchaseFlowId) {
    const selectedTip = rootStore.$state.selectedTip;
    if (selectedTip) {
      const tipToSave = { ...selectedTip, value: Number(selectedTip.value) };
      const { purchaseFlowId } = checkout;
      return setTip(httpClient, {
        purchaseFlowId,
        tip: toJS(tipToSave),
      })
        .then(() => {
          runInAction(() => {
            rootStore.$state.error = undefined;
          });
          sendTipAddedBiEvent(rootStore, tipToSave);
          fedops.interactionEnded(FedopsInteractions.SET_TIP);
          return { success: true };
        })
        .catch((error) => handleErrorResponse(error, rootStore))
        .finally(() => {
          if (rootStore.refreshCheckout) {
            rootStore.refreshCheckout();
          }
        });
    } else {
      return { success: false };
    }
  } else {
    return { success: false };
  }
};

export const updateTipDebounced = debounce(updateTip, 500);
