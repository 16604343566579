import { RootStore, BindParams } from '../../RootStore/RootStore';
import CustomTipField from './CustomTipField/CustomTipField.bind';
import CustomTipButton from './CustomTipButton/CustomTipButton.bind';
import CustomTipSelection from './CustomTipSelection/CustomTipSelection.bind';

const bind = (rootStore: RootStore): BindParams => {
  return {
    ...CustomTipButton(rootStore),
    ...CustomTipField(rootStore),
    ...CustomTipSelection(),
  };
};

export default bind;
