import { ISettings, API_BASE_URL } from '@wix/wix-tips-common';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getSettings = (httpClient: IHttpClient) => {
  return httpClient
    .get<{ settings: ISettings }>(`/${API_BASE_URL}/settings`)
    .then((res) => res.data.settings)
    .catch((err) => {
      console.error('Failed to get settings', err);
      return undefined;
    });
};
