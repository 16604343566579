import { BindParams, RootStore } from '../../RootStore/RootStore';
import { ComponentIds as Ids } from '../../config/constants';

const bind = (rootStore: RootStore): BindParams => {
  const { $state } = rootStore;
  return {
    [Ids.ErrorOuterBox]: {
      accessibility: { role: () => ($state.error ? 'alert' : 'none') },
    },
    [Ids.ErrorInnerBox]: {
      deleted: () => !$state.error,
    },
    [Ids.ErrorText]: {
      text: () => $state.error ?? '',
    },
  };
};

export default bind;
