import { RootStore } from './RootStore';
import RootBox from '../components/RootBox/RootBox.bind';
import Title from '../components/Title/Title.bind';
import RadioGroup from '../components/RadioGroup/RadioGroup.bind';
import Presets from '../components/Presets/Presets.bind';
import CustomTip from '../components/CustomTip/CustomTip.bind';
import Error from '../components/Error/Error.bind';

export const bindProps = (rootStore: RootStore) => {
  const { $bindAll } = rootStore;
  $bindAll({
    ...RootBox(rootStore),
    ...Title(rootStore),
    ...RadioGroup(rootStore),
    ...Presets(rootStore),
    ...CustomTip(rootStore),
    ...Error(rootStore),
  });
};
