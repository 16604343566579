import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'TipsNew',
  props: {
    checkoutId: {
      type: WidgetPropertyType.STRING,
    },
  },
  methods: {
    onRefreshCheckout: {},
  },
  events: {},
});
