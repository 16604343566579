import { BindParams, RootStore } from '../../RootStore/RootStore';
import { ComponentIds as Ids } from '../../config/constants';

const bind = (rootStore: RootStore): BindParams => {
  const { t } = rootStore;
  return {
    [Ids.Title]: {
      text: () => t('checkout.tips.title'),
    },
  };
};

export default bind;
