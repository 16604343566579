import { getCheckout as _getCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export const getCheckout = async (
  httpClient: IHttpClient,
  checkoutId: string,
): Promise<Checkout | undefined> => {
  return httpClient
    .request(_getCheckout({ id: checkoutId }))
    .then((response) => {
      return response.data.checkout;
    })
    .catch((err) => {
      console.error('Failed to get checkout', err);
      return undefined;
    });
};
