import { RootStore, BindParams } from '../../../RootStore/RootStore';
import {
  SelectedOption,
  ComponentIds as Ids,
  EnabledOptions,
} from '../../../config/constants';
import { TipTypes } from '@wix/wix-tips-common';
import { autorun, makeAutoObservable, runInAction } from 'mobx';
import { isValidTipValue } from './isValidTipValue';

const ButtonState = {
  Apply: 'apply',
  Success: 'success',
};

const bind = (rootStore: RootStore): BindParams => {
  const { t, $state, $w } = rootStore;
  const $fieldState = makeAutoObservable({
    showSuccessState: false,
    get buttonState() {
      return $fieldState.showSuccessState
        ? ButtonState.Success
        : ButtonState.Apply;
    },
  });
  const onApply = async () => {
    const value = $w(Ids.CustomTipInput).value;
    const tip = {
      type: TipTypes.Amount,
      value,
    };
    runInAction(() => {
      $state.keepValidated = true;
      $state.selectedTip = tip;
    });
    $w(Ids.CustomTipInput).updateValidityIndication();
    const { isValid } = isValidTipValue(value, rootStore);
    if (!isValid) {
      $w(Ids.CustomTipInput).focus();
    } else {
      const { success } = await rootStore.updateTip();
      if (success) {
        runInAction(() => {
          $fieldState.showSuccessState = true;
        });
      }
    }
  };

  const validator: $w.Validator = (value, reject) => {
    if ($state.keepValidated) {
      const { isValid, error } = isValidTipValue(String(value), rootStore);
      if (!isValid) {
        reject(error);
      }
      runInAction(() => {
        $state.error = isValid ? undefined : error;
      });
    }
  };

  const controlValue = () => {
    const input = $w(Ids.CustomTipInput);
    if ($state.selectedOption !== SelectedOption.Custom) {
      input.value = '';
    } else {
      const valueToSet = $state.selectedTip?.value;
      const alreadySameValue = input.value === valueToSet;
      if (!alreadySameValue) {
        if ($state.isUserSelectedCustomTip && Number(valueToSet) === 0) {
          input.value = '';
        } else {
          input.value = valueToSet ?? '';
        }
      }
    }
  };
  autorun(controlValue);
  $w(Ids.CustomTipInput).onCustomValidation(validator, true);
  $w(Ids.CustomTipInput).hideNumberSpinner();
  return {
    [Ids.CustomTipFieldBox]: {
      deleted: () =>
        !(
          ($state.enabledOptions === EnabledOptions.All &&
            $state.selectedOption === SelectedOption.Custom) ||
          $state.enabledOptions === EnabledOptions.CustomOnly
        ),
    },
    [Ids.CustomTipInput]: {
      prefix: () => $state.currencySymbol ?? /* istanbul ignore next */ '',
      placeholder: () => '',
      required: () => $state.keepValidated,
      onKeyPress: (event: any) => {
        /* istanbul ignore else */
        if (event.key === 'Enter') {
          onApply();
        }
      },
      onInput: () => {
        runInAction(() => {
          $fieldState.showSuccessState = false;
        });
        $w(Ids.CustomTipInput).updateValidityIndication();
      },
      onChange: () => {
        runInAction(() => {
          $fieldState.showSuccessState = false;
        });
      },
      accessibility: {
        ariaAttributes: {
          label: () =>
            t('checkout.tips.customAmountCurrency.ariaLabel', {
              currency: $state.currency,
            }),
        },
      },
    },
    [Ids.CustomTipApplyButton]: {
      onClick: () => onApply(),
      label: () => t('checkout.tips.customAmount.cta'),
    },
    [Ids.CustomTipSuccessButton]: {
      accessibility: {
        ariaAttributes: {
          label: () => t('checkout.tips.customAmountApplied.ariaLabel'),
        },
      },
    },
    [Ids.ButtonStateBox]: {
      currentState: () => $fieldState.buttonState,
    },
  };
};

export default bind;
