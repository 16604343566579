import model from './model';
import { RootStore } from './RootStore/RootStore';

export default model.createController((controllerParams) => {
  const rootStore = new RootStore(controllerParams);
  return {
    pageReady: async () => {
      await rootStore.pageReady().catch(console.error);
    },
    exports: {
      onRefreshCheckout(refreshCheckoutCallback: () => Promise<void>) {
        rootStore.refreshCheckout = refreshCheckoutCallback;
      },
    },
  };
});
