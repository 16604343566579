import { RootStore, BindParams } from '../../RootStore/RootStore';
import { SelectedOption, ComponentIds as Ids } from '../../config/constants';
import { autorun, runInAction } from 'mobx';
import { TipTypes } from '@wix/wix-tips-common';

const toData = (values: number[]) =>
  values.map((value, index) => ({
    _id: `preset-${index}`,
    value,
    index,
  }));

const bind = (rootStore: RootStore): BindParams => {
  const { $state, settings, $w } = rootStore;
  const { type, values } = settings.presets;
  const flowAPI = rootStore.controllerParams.flowAPI;
  const decimalFormat = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  };
  const formatCurrency = flowAPI.getCurrencyFormatter(decimalFormat);
  const numberFormatter = Intl.NumberFormat(flowAPI.environment.language, {
    ...decimalFormat,
    style: 'percent',
  });
  const formatPercent = (value: number) => numberFormatter.format(value / 100);
  const amountLabel = (value) =>
    formatCurrency({
      value,
      currency:
        $state.currency ||
        /* istanbul ignore next reason: no need to test */ 'USD',
    });

  const controlSelection = () => {
    const indexToSelect = $state.presetIndex;
    const repeater = $w(Ids.PresetsRepeater);
    if ($state.selectedOption === SelectedOption.Presets) {
      const alreadySelected = repeater.selectedIndex === indexToSelect;
      /* istanbul ignore else reason: optimization for not set index twice */
      if (!alreadySelected) {
        /* istanbul ignore else reason: optimization for not setting null as index */
        if (indexToSelect !== null) {
          repeater.selectedIndex = indexToSelect;
        } else {
          repeater.clearSelection();
        }
      }
    } else {
      repeater.clearSelection();
    }
  };

  autorun(controlSelection);
  return {
    [Ids.PresetsRepeater]: {
      deleted: () => !$state.isPresetsEnabled,
      data: () => ($state.isPresetsEnabled ? toData([0, ...values]) : []),
      item: ({ value }) => ({
        [Ids.PresetsRepeaterItem]: {
          inputLabel: () => {
            return type === TipTypes.Amount
              ? amountLabel(value)
              : formatPercent(value);
          },
        },
      }),
      onItemSelected: (_, { value }) => {
        const tip = { type, value };
        runInAction(() => {
          $state.selectedTip = tip;
          $state.isUserSelectedCustomTip = false;
          $state.keepValidated = false;
        });
        rootStore.updateTipDebounced();
      },
    } as any,
  };
};

export default bind;
