import { BindParams, RootStore } from '../../RootStore/RootStore';
import { ComponentIds as Ids } from '../../config/constants';

const bind = (rootStore: RootStore): BindParams => {
  const { $state, $w } = rootStore;
  return {
    [Ids.RootBox]: {
      deleted: () => !$state.purchaseFlowId,
      accessibility: {
        ariaAttributes: {
          labelledBy: () => $w(Ids.Title),
        },
        role: () => 'group',
      },
    },
  };
};

export default bind;
