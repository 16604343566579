import { RootStore } from '../../../RootStore/RootStore';

const numberPattern = '^-?[0-9]+(.[0-9]+)?$';
const numberRegExp = new RegExp(numberPattern);
const twoDecimalsPattern = '^\\d+(?:\\.\\d{0,2})?$';
const twoDecimalsRegExp = new RegExp(twoDecimalsPattern, 'i');

export const isValidTipValue = (
  value: string,
  rootStore: RootStore,
): { isValid: boolean; error?: string } => {
  const t = rootStore.t;
  if (value === '') {
    return {
      isValid: false,
      error: t('checkout.tips.validationError.emptyField'),
    };
  } else {
    if (!numberRegExp.test(value)) {
      return {
        isValid: false,
        error: t('checkout.tips.validationError.noCharactersLetters'),
      };
    }
    if (Number(value) < 0) {
      return {
        isValid: false,
        error: t('checkout.tips.validationError.negativeAmount'),
      };
    }
    if (!twoDecimalsRegExp.test(value)) {
      return {
        isValid: false,
        error: t('checkout.tips.validationError.tooManyDigits'),
      };
    }
  }
  return { isValid: true };
};
