import { IHttpClient } from '@wix/yoshi-flow-editor';
import { ITip, API_BASE_URL } from '@wix/wix-tips-common';

export const getTip = (
  httpClient: IHttpClient,
  { purchaseFlowId }: { purchaseFlowId: string },
) => {
  return httpClient
    .get<{ tip?: ITip }>(`/${API_BASE_URL}/tips/${purchaseFlowId}`)
    .then((res) => res.data.tip)
    .catch((err) => {
      console.error('Failed to get tip', err);
      return undefined;
    });
};

export const setTip = (
  httpClient: IHttpClient,
  { purchaseFlowId, tip }: { purchaseFlowId: string; tip: ITip },
) => {
  return httpClient
    .put<{ tip: ITip }>(`/${API_BASE_URL}/tips/${purchaseFlowId}`, { tip })
    .then((res) => res.data.tip);
};

export const deleteTip = (
  httpClient: IHttpClient,
  { purchaseFlowId }: { purchaseFlowId: string },
) => {
  return httpClient.delete<void>(`/${API_BASE_URL}/tips/${purchaseFlowId}`);
};
