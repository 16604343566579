import { BindParams, RootStore } from '../../RootStore/RootStore';
import { ComponentIds as Ids } from '../../config/constants';

const bind = (rootStore: RootStore): BindParams => {
  const { $state } = rootStore;
  return {
    [Ids.RadioGroupBox]: {
      deleted: () => !$state.isPresetsEnabled,
      accessibility: {
        role: () => 'radiogroup',
      },
    },
  };
};

export default bind;
