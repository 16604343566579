import { RootStore } from './RootStore';

export type ReportFedopsInteractionFn = (
  interaction: string,
  fn: () => unknown,
) => Promise<void>;

export type ReportFedopsFn = (
  rootStore: RootStore,
) => ReportFedopsInteractionFn;

export const createReportFedopsInteraction: ReportFedopsFn =
  (rootStore) =>
  async (interaction, fn): Promise<any> => {
    rootStore.controllerParams.flowAPI.fedops.interactionStarted(interaction);
    const ret = await fn();
    rootStore.controllerParams.flowAPI.fedops.interactionEnded(interaction);
    return ret;
  };

export const enum FedopsInteractions {
  SET_TIP = 'set-tip',
}
