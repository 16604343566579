import { Checkout } from '@wix/ambassador-ecom-v1-checkout/build/cjs/types.impl';
import { ITip, TipTypes } from '@wix/wix-tips-common';
import { makeAutoObservable } from 'mobx';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';
import { RootStore } from './RootStore';
import { EnabledOptions, SelectedOption } from '../config/constants';

export class RootState {
  public checkout: Checkout | undefined;
  public selectedTip: (Omit<ITip, 'value'> & { value: string }) | undefined;
  public isUserSelectedCustomTip: boolean;
  public keepValidated: boolean;
  public error: string | undefined;

  constructor(private readonly rootStore: RootStore) {
    this.checkout = undefined;
    this.selectedTip = undefined;
    this.isUserSelectedCustomTip = false;
    this.keepValidated = false;
    this.error = undefined;
    makeAutoObservable(this);
  }

  public reset(checkout?: Checkout, savedTip?: ITip) {
    this.checkout = checkout;
    this.selectedTip = savedTip
      ? { ...savedTip, value: String(savedTip.value) }
      : undefined;
    this.error = undefined;
    this.isUserSelectedCustomTip = false;
    this.keepValidated = false;
  }

  public get purchaseFlowId() {
    return this.checkout?.purchaseFlowId;
  }

  public get currency() {
    return (
      this.checkout?.currency ||
      this.rootStore.controllerParams.controllerConfig.wixCodeApi.site.currency
    );
  }

  public get currencySymbol() {
    const flowAPI = this.rootStore.controllerParams.flowAPI;
    const language = flowAPI.translations.config.language;
    const { currency } = this;
    return /* istanbul ignore else */ currency
      ? getCurrencySymbol(currency, language)
      : '';
  }

  public get presetIndex(): number | null {
    const { values, defaultIndex } = this.rootStore.settings.presets;
    const hasDefault = defaultIndex !== null;
    let index: number | null = null;
    if (this.selectedTip) {
      index = [0, ...values].findIndex(
        (v) => v === Number(this.selectedTip!.value),
      );
    } else if (hasDefault) {
      index = defaultIndex + 1;
    } else {
      index = 0;
    }
    return index > -1 ? index : null;
  }

  public get isCustomValueEnabled(): boolean {
    const { customValue } = this.rootStore.settings;
    return !!customValue?.enabled;
  }

  public get isPresetsEnabled(): boolean {
    const { presets } = this.rootStore.settings;
    return presets.values.length > 0;
  }

  public get enabledOptions(): EnabledOptions {
    if (this.isPresetsEnabled) {
      if (this.isCustomValueEnabled) {
        return EnabledOptions.All;
      }
      return EnabledOptions.PresetsOnly;
    } else {
      if (this.isCustomValueEnabled) {
        return EnabledOptions.CustomOnly;
      }
      return EnabledOptions.None;
    }
  }

  public get selectedOption(): SelectedOption {
    const { presets } = this.rootStore.settings;
    const {
      selectedTip,
      isUserSelectedCustomTip,
      isCustomValueEnabled,
      isPresetsEnabled,
    } = this;
    if (!selectedTip && isPresetsEnabled && !isUserSelectedCustomTip) {
      return SelectedOption.Presets;
    } else {
      const isInCustomTip = isUserSelectedCustomTip && isCustomValueEnabled;
      if (isInCustomTip) {
        return SelectedOption.Custom;
      } else {
        const isInPresets =
          !!selectedTip &&
          [0, ...presets.values].includes(Number(selectedTip?.value));
        const hasSamePresetType = selectedTip?.type === presets.type;
        if (
          isInPresets &&
          hasSamePresetType &&
          isPresetsEnabled &&
          !isUserSelectedCustomTip
        ) {
          return SelectedOption.Presets;
        } else {
          const isAmount = selectedTip?.type === TipTypes.Amount;
          if (isAmount && isCustomValueEnabled) {
            return SelectedOption.Custom;
          } else {
            return SelectedOption.None;
          }
        }
      }
    }
  }
}
