import { getSettings } from '../../../api/settings/SettingsApi';
import { ControllerParams } from '../config/types';
import { bindProps } from './bindProps';
import { getInitialStateData } from './getInitialStateData';
import { RootState } from './RootState';
import { ISettings } from '@wix/wix-tips-common';
import { updateTip, updateTipDebounced } from './updateTip';
import { ComponentIds } from '../config/constants';
import { createReportFedopsInteraction } from './fedops';

export type BindParams = Parameters<RootStore['$bindAll']>[0];

export class RootStore {
  public settings!: ISettings;
  public resolvePageReady?: (value?: unknown) => void;
  constructor(public readonly controllerParams: ControllerParams) {
    //
  }

  public reportFedopsInteraction = createReportFedopsInteraction(this);

  public get httpClient() {
    return this.controllerParams.flowAPI.essentials.httpClient;
  }

  public get $props() {
    return this.controllerParams.$props;
  }

  public get fedops() {
    return this.controllerParams.flowAPI.fedops;
  }

  public get $bindAll() {
    return this.controllerParams.$bindAll;
  }

  public get t() {
    return this.controllerParams.flowAPI.translations.t;
  }

  public get $w() {
    return this.controllerParams.$w;
  }

  public get experiments() {
    return this.controllerParams.flowAPI.experiments;
  }

  public $state = new RootState(this);

  public updateTipDebounced = async () => {
    return updateTipDebounced(this);
  };

  public updateTip = async () => {
    return updateTip(this);
  };

  private setInitialStateData = async () => {
    const { checkout, savedTip } = await getInitialStateData(this);
    this.$state.reset(checkout, savedTip);
  };

  public pageReady = async () => {
    this.$w(ComponentIds.CustomTipApplyButton).collapseIcon();
    const settings = await getSettings(this.httpClient);
    if (!settings || !settings.enabled || !settings.visible) {
      this.$w(ComponentIds.RootBox).delete();
    } else {
      this.settings = settings;
      this.controllerParams.$widget.onPropsChanged(this.setInitialStateData);
      await this.setInitialStateData();
      bindProps(this);
    }
  };

  public refreshCheckout: (() => Promise<void>) | undefined = undefined;
}
